import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import '../assets/ModalPopUp.css';

const palestine = require("../assets/imgs/vote.jpg");
const vote = require("../assets/imgs/vote1.jpg");

const ScrollTriggeredModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowModal(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Vote Mohammed For Congress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Slider {...settings}>
            <div>
            <img src={vote} class="modal_image" alt="vote campaign brochure" />
            </div>
            <div>
            <img src={palestine} class="modal_image" alt="vote campaign brochure 2" />
            </div>
          </Slider>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ScrollTriggeredModal;


