export const issues_data = [
  {
    "title": "Affordable housing",
    "image": "fa-solid fa-house",
    "description":"Expanding access to safe and affordable housing is crucial. We must prioritize the development of more affordable housing to ensure everyone receives support."
  },
  {
    "title": "Stop the youth access to drugs",
    "image": "fa-solid fa-pills",
    "description":"Implementing comprehensive policies to curb youth access to drugs is essential. Strengthening educational programs and enforcement efforts can effectively stop drug proliferation."
  },
  {
    "title": "Security and gun control",
    "image": "fa-solid fa-shield",
    "description":"Ensuring security and responsible gun control measures is crucial. Advocating for sensible gun legislation and investing in mental health resources can protect public safety."
  },
  {
    "title": "Work against racial discrimination and islamophobia",
    "image": "fa-solid fa-star-and-crescent",
    "description":"Combatting racial discrimination and Islamophobia is fundamental. Through education and legislation, we can foster a culture of acceptance and respect for all."
  },
  {
    "title": "Stop the killing of Palestinians",
    "image": "fa-solid fa-ban",
    "description":"Halt the killing of Palestinians with diplomacy and human rights. Supporting peaceful negotiations and providing humanitarian aid is essential."
  },
  {
    "title": "Access and opportunities for migrants",
    "image": "fa-regular fa-handshake",
    "description":"Ensuring equal access and opportunities for migrants is crucial. Advocating for immigration reform and investing in migrant communities can create a society where everyone can thrive."
  }
];
