import React from "react";
import "../assets/Main.css";


const queens= require("../assets/imgs/bravo-fPoSK5XeyHw-unsplash_thanks.jpg")
function Main() {
  return (
   <>
   <div class="container">
   <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      {/* <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li> */}
    </ul>
    <p class="text-center text-muted">© 2024 Mohammed for Congress</p>
    <p class="text-center text-muted">samratint@aol.com</p>
  </footer>
   </div>
</>
  );
}
export default Main;
