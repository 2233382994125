import React from 'react';
import "../assets/Images.css";

const family = require("../assets/imgs/family.jpg");
const greet = require("../assets/imgs/greet.jpg");
const mosque = require("../assets/imgs/mosque.jpg");
const support = require("../assets/imgs/support.jpg");
const walk = require("../assets/imgs/walk.jpg");
const vote = require("../assets/imgs/vote.jpg");
const vote1 = require("../assets/imgs/vote1.jpg");

function ImageCollage() {
  return (
    <div className="image-collage">
      <h2>Gallery</h2>
      <div className="image-grid">
        <img src={family} className="image" alt="Image 1" />
        <img src={greet} className="image" alt="Image 2" />
        <img src={mosque} className="image" alt="Image 3" />
        <img src={support} className="image" alt="Image 4" />
        <img src={walk} className="image" alt="Image 5" />
        <img src={vote} className="image" alt="Image 6" />
        <img src={vote1} className="image" alt="Image 7" />
      </div>
    </div>
  );
}

export default ImageCollage;
