import React from "react";
import "../assets/Navbar.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// Importing Issues and Contact components
import Issues from "./Issues";
import Contact from "./Contact";

function Navbar() {
  return (
    <>
   
      <nav className="navbar navbar-expand-lg navbar-light text-white">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
            <div className="navbar-nav">
           
              <a  className="nav-link" href="About"> 
                About 
                </a>
            

          
              <a className="nav-link" href="#Issues"> 
                Issues
                </a>
      

             
              <a className="nav-link" href="#Contact"> 
                Contact
                </a>

       
            </div>
          </div>
        </div>
      </nav>

      <Routes> 
        {/* <Route exact path="/Issues" element={<Issues />} /> */}
        <Route exact path="/Contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default Navbar;
