import React from "react";
import "../assets/Contact.css";

// const queens = require("../assets/imgs/luca-bravo-fPoSK5XeyHw-unsplash.jpg");
function Contact() {
  return (
    <>
      <div className="container Contact" id="Contact">
        <div className="row">
          <div className="col-lg-12">
            <p class="slogan">Your brother will correct the wrongs</p>
          </div>
          {/* <div className="col-lg-6 email_form">.col-6 </div> */}
        </div>
      </div>
    </>
  );
}
export default Contact;
