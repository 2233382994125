import React from "react";
import "../assets/Bio.css";

const american_flag= require("../assets/imgs/american_flag.png");
function Bio() {
  return (
    <>
      <div class="container bio">
        <div class="row">
          <img class="flag" src={american_flag} alt="american flag"/>
          <div class="col-12 bio_description no-gutters">
            <h1 class="district">Queens District 6</h1>
            Vote Mohammed, a dedicated home-loving husband and experienced
            accountant, in his bid for Congress. With over three decades of
            residency in Queens, Mohammed is deeply rooted in his community. A
            regular attendee of his local mosque, he understands the importance
            of faith and community values. Together, let's elect Mohammed to Congress
            and ensure our voices are heard in Washington.
          </div>
          <div class="line"></div>
        </div>
      </div>
    </>
  );
}
export default Bio;
