import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from "./components/Navbar";
import ModalPopUp from "./components/ModalPopUp";
import Main from "./components/Main";
import Bio from "./components/Bio";
import Issues from "./components/Issues";
import Images from "./components/Images";
import Contact from "./components/Contact";
import Footer from "./components/Footer";


function App() {
  return (
    <Router>
      <ModalPopUp/>
      <Navbar/>
      <Main/>
      <Bio/>
      <Issues/>
      <Images/>
      <Contact/>
      <Footer/>

   
    </Router>
  );
}

export default App;
