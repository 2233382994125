import React from "react";
import "../assets/Issues.css";
import {issues_data} from "../components/issuesjson.js";

function Issues() {
  return (
    <>
<div className="container text-center issues" id="Issues">
  <div className="row">
    <div className="col-12 title">
      <p>Political Issues</p>
    </div>
  </div>
  
  <div className="row">
    {issues_data.map((issue, index) => (
      <div key={index} className="col-md-4 mb-3">
        <div className="card issue">
          <div className="card-body">
            <h5 className="card-title">{issue.title}</h5>
            <p className="description">{issue.description}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>


   
    </>
  );
}
export default Issues;
