import React from "react";
import "../assets/Main.css";


const queens= require("../assets/imgs/bravo-fPoSK5XeyHw-unsplash_thanks.jpg")
function Main() {
  return (
   <>
<div className="row main">
   <div className="col-md-12">
      <div className="card">
         <div className="card-overlay"></div>
         
         <img className="queens_train" src={queens} alt="queens train 7"/>
         <div className="card-block">
            <h1><span class="vote"> Vote</span> <span class="name">Mohammed</span></h1>
            <h3 className="vote_">Mohammed For Congress District 6</h3>
            <p class="political_campaign">Our political agenda centers on vital issues affecting our communities. From ensuring access to affordable housing to tackling youth drug abuse, promoting security measures, and combating racial discrimination and Islamophobia, we advocate for inclusive policies. We stand against violence in all forms, including the unjust treatment of Palestinians, and strive to provide migrants with equitable access to opportunities. Together, let's forge a future where every individual can thrive and contribute to a society built on fairness and justice.</p>
         </div>
      </div>
   </div>
</div>
</>
  );
}
export default Main;
